// Here you can add other styles

@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
body,
.table {
  font-family: 'Tajawal', sans-serif;
  font-size: 14px;
}

// main area background color
.bg-light {
  background-color: #f0f4f7 !important;
}
// sidebar active nav link
.sidebar-nav .nav-link.active {
  background: #247ba0 !important;
}
// link styles
a {
  color: #3899ec;
}

// scrollbar style
::-webkit-scrollbar {
  width: 20px; /* width of the entire scrollbar */
}
::-webkit-scrollbar-track {
  background: #cad9b2; /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
  background-color: #5b7553;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}
// customise datepicker clear button
.react-datepicker__close-icon {position:static !important;}
.Master-Layout-Body{
    height: auto;
}


.headerS{
  text-align: center;
  margin-right: 250px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.pagination {
    display: inline-block;
  }

  .pagination button {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #ddd;
  }
  .pagination button.active {
    background-color: #4CAF50;
    color: white;
    border: 1px solid #4CAF50;
  }

  .pagination button:hover:not(.active) {background-color: #ddd;}
  .exit-button{
    margin-left: 15px;
  }
  .exit-button.hover{
    background-color: red;
  }

  input[type='number']{ line-height: 28px; }

  .scrollable{
    max-width: 100%;
    // max-height: 40%;
    overflow-x: scroll;
    // overflow-y: scroll;
  }

  .select{
    width: 150px;
  }

  .bodywidth{
    width: auto;
  }

  .editableTable {
    width: 100%;
    border-spacing: 0;
    border: 1px solid black;
    background-color: transparent;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      position: sticky;
      top: 0px;  /* 0px if you don't have a navbar, but something is required */
      background-color: #ebedef;
      border: 1px solid black;
    }
    td {
      width: auto;
      max-width: 100%;
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        background-color: transparent;
        padding: 0;
        margin: 0;
        border: 0;
        width: auto;
        max-width: 100%;
      }
    }
  }
  .dateCol .datePicker{
    width: 285px;
  }

  .pagination {
    padding: 0.5rem;
  }


//   .dropdown-item {
// background-color: #4CAF50;
// }
// :onclick
.dropdown-item.active, .dropdown-item:active {
  color: var(--cui-dropdown-link-active-color);
  text-decoration: none;
  background-color: #41bd3d;
}


// navbar
.dropdown-submenu-container {
  position: relative;
}
.dropdown-submenu-container a.dropdown-submenu::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: 0.8em;
}
.dropdown-submenu-container .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: 1px;
  margin-right: auto;
}
.dropdown-menu-right .dropdown-submenu-container .dropdown-menu {
  left: auto;
  right: 100%;
  margin-left: auto;
  margin-right: 1px;
}
a{
  text-decoration: none;
  color: #000000;
}


.customModal{
  border:none;
// background-color: #000000;

opacity: 1;
}

.modal-content{
  border:none;
  // opacity: 1;

}

.colCharitablEexchange .datePickerCharitablEexchange{
  width: 140px;
}

.rdg-example{
  display: grid;
  grid-template-columns:repeat(auto-fill,minmax(250px,1fr)) ;
  row-gap: 100px;
  column-gap: 50px;
}


 .loaderLoding {
  width: 82px;
  height: 18px;
  position: relative;
}
.loaderLoding::before , .loaderLoding::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translate(-50% , 10%);
  top: 0;
  background: #321fdb;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  animation: jump 0.5s ease-in infinite alternate;
}

.loaderLoding::after {
  background: #0000;
  color: rgba(44, 56, 74, 0.95);
  top: 100%;
  box-shadow: 32px -20px , -32px -20px;
  animation: split 0.5s ease-out infinite alternate;
}

@keyframes split {
  0% { box-shadow: 8px -20px, -8px -20px}
  100% { box-shadow: 32px -20px , -32px -20px}
}
@keyframes jump {
 0% { transform: translate(-50% , -150%)}
 100% { transform: translate(-50% , 10%)}
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}


#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ql-editor {
  direction: rtl;
  text-align: right;
}

.form-control{
  padding: 1px;
}

// .footer{
//   height: 3px !important;
//   margin: 0px;
//   padding: 0px;
// }

.MasterLayoutHeader{
height: 1px !important;
padding: 0px !important;
// background-color: #41bd3d;
}

.row{
  margin-bottom: 3px !important;
}
.nav-link{
  padding: 4px !important;
}

.text-bg-light{
  color: black !important;
  background-color: #ffffff !important;
}

.form-control:disabled{
  color: black !important;
  background-color:#fff9ee
}
.css-16xfy0z-control{
  background-color:#fff9ee !important;
}
.css-olqui2-singleValue{
  color: black !important;
  background-color:#fff9ee
}
.react-datepicker__input-container input:disabled{
  color: black !important;
  background-color:#fff9ee
}
.MasterLayoutHeader{
  background-color: #780b16 !important;
}
.MasterLayoutHeader .text-start{
  color: rgb(255, 255, 255) !important;
}
.footer {
  background-color:#ececec!important;
}
.form-label{
  color: black !important;
}
input{
  height: 25px;
}
.css-3iigni-containerl{
  height: 25px !important;
}
label{
  height:25px !important;}

// .modal-header{
//   height: 10px;
// }

// .modal-footer{
//   height: 35px !important;
//   margin: 1px !important;
//   padding: 0px !important;
// }
// .modal-footer .btn{
//   margin: 1px !important;
//   position: relative;
//   height: 35px !important;
// }
// .colspan{
//   background-color: #41bd3d;
//   width: 1px !important;
// }
// .active{
//   background-color: transparent;
// }
